.triangle-right {
    background-image: linear-gradient(to left top, #ffffff 0%, #ffffff 50%, transparent 50%);
    position: absolute;
    width: 3600px;
    height: 310px;
    bottom: 0;
    left: 0;
}

.triangle-left {
    background-image: linear-gradient(to right top, #bd0926 0%, #bd0926 50%, transparent 50%);
    position: absolute;
    width: 3600px;
    height: 240px;
    bottom: 0;
    right: 0;
}