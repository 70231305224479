.googlemap {
    
	height: 0;
	overflow: hidden;
	padding-bottom: 60%; /* aspect ratio */
    position: relative;
        
    iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}