

.z-1 {
    z-index: 1;
}

.top-0 {
    top: 0px;
}

.no-deco {
    text-decoration: none !important;
    &:hover {
        text-decoration: none !important;   
    }
}

.bg-grey-300 {
    background-color: $gray-300;
}

.round-button {
    border-radius: 30rem;
}

.bg-cover {
    background-size: cover;
}

.bottom-1 {
    bottom: -1px;
}

.bottom-0 {
    bottom: 0px;
}

.custom-text-shadow {
    text-shadow: 0px 0px 20px #656565;
}
  
.zoom-effect-container {
    overflow: hidden;
    img {
        transition: transform .2s; /* Animation */
    }
    
    &:hover img {
        transform: scale(1.06);
    }
}

  .hidden-overflow {
      overflow: hidden;
  }

  .max-width-180 {
    max-width: 180px;
  }

@media (max-width: 768px) { 
    .no-border-mobile {
        border: none !important;
    }      
}

