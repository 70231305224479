@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "node_modules/bootstrap/scss/functions";
@import "bootstrap_vars"; /* we use our own vars! */
@import "node_modules/bootstrap/scss/bootstrap";

@import "video_embeds";
@import "contact";
@import "utility_classes";
@import "shapes";